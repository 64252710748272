<template>
  <div class="content">
    <div class="card">
      <div class="card-header d-flex align-items-center py-0">
        <h4 class="text-secondary py-3 mb-0">
          Funcionários
          <span class="fs-base">(Empresa: {{ companyCode }})</span>
        </h4>
        <div class="ms-auto">
          <router-link
            to="/managers/home"
            class="btn btn-outline-dark me-2"
            title="Voltar Para à Tela Inicial"
          >
            Voltar Para à Tela Inicial
          </router-link>
          <router-link
            to="collaborators/new"
            class="btn btn-secondary"
            title="Novo Funcionário"
          >
            <i class="ph ph-user-plus me-2"></i>
            Novo Funcionário
          </router-link>
        </div>
      </div>
      <LoadingData v-if="loadData" />
      <CollaboratorsTable v-else :collaborators="collaborators" />
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import CollaboratorsTable from '@/components/managers/collaborators/CollaboratorsTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ListCollaborators',

  components: {
    LoadingData,
    CollaboratorsTable,
  },

  data() {
    return {
      collaborators: [],
      loadData: true,
    }
  },

  computed: {
    ...mapGetters(['companyCode']),
  },

  methods: {
    async getCollaborators() {
      try {
        this.loadData = true
        this.collaborators = await this.$store.dispatch('getCollaborators', {
          companyCode: this.companyCode,
          search: '',
        })
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getCollaborators()
  },
}
</script>
