<template>
  <div class="content">
    <div class="card">
      <div class="card-header d-flex align-items-center py-0">
        <h4 class="text-secondary py-3 mb-0">
          Atualizar Funcionário
          <span class="fs-base">(Empresa: {{ companyCode }})</span>
        </h4>
        <div class="ms-auto">
          <router-link
            to="/managers/collaborators"
            class="btn btn-outline-dark"
            title="Listagem de Funcionários"
          >
            Listagem de Funcionários
          </router-link>
        </div>
      </div>
      <form @submit.prevent="save">
        <div class="card-body">
          <LoadingData v-if="loadData" />
          <div v-else class="row g-3">
            <div class="col-12">
              <label class="form-label">
                Unidade: <span class="text-danger">*</span>
              </label>
              <select
                class="form-select"
                :class="{
                  'is-invalid': v$.collaborator.unity.$errors.length,
                }"
                v-model="v$.collaborator.unity.$model"
              >
                <option value="">Selecione uma unidade</option>
                <option
                  v-for="unity in unities"
                  :key="unity.unityCode"
                  :value="unity.unityCode"
                  :title="unity.unityName"
                >
                  {{ unity.unityCode }} - {{ unity.unityName }} ({{
                    unity.unityCnpj
                  }})
                </option>
              </select>
              <span
                v-if="v$.collaborator.unity.required.$invalid"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <template v-if="collaborator.unity">
              <div class="col-sm-6">
                <label class="form-label">
                  Setor: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.collaborator.department.$errors.length,
                  }"
                  v-model="v$.collaborator.department.$model"
                >
                  <option value="">Selecione um setor</option>
                  <option
                    v-for="department in departments"
                    :key="department.departmentCode"
                    :value="department.departmentCode"
                    :title="department.departmentName"
                  >
                    {{ department.departmentCode }} -
                    {{ department.departmentName }}
                  </option>
                </select>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Cargo: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.collaborator.position.$errors.length,
                  }"
                  v-model="v$.collaborator.position.$model"
                >
                  <option value="">Selecione um cargo</option>
                  <option
                    v-for="position in positions"
                    :key="position.id"
                    :value="position.positionCode"
                    :title="position.positionName"
                  >
                    {{ position.positionCode }} - {{ position.positionName }}
                  </option>
                </select>
                <span
                  v-if="v$.collaborator.position.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-12">
                <label class="form-label">
                  Nome: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.collaborator.name.$errors.length,
                  }"
                  placeholder="Nome"
                  v-model="v$.collaborator.name.$model"
                />
                <span
                  v-if="v$.collaborator.name.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Data de Nascimento: <span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.collaborator.birthday.$errors.length,
                  }"
                  placeholder="00/00/0000"
                  v-model="v$.collaborator.birthday.$model"
                />
                <span
                  v-if="v$.collaborator.birthday.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="v$.collaborator.birthday.minLength.$invalid"
                  class="invalid-feedback"
                >
                  Formato: 00/00/0000
                </span>
                <span
                  v-if="v$.collaborator.birthday.maxLength.$invalid"
                  class="invalid-feedback"
                >
                  Formato: 00/00/0000
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  CPF: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.collaborator.cpf.$errors.length,
                  }"
                  placeholder="000.000.000-00"
                  v-model="v$.collaborator.cpf.$model"
                  v-maska="'###.###.###-##'"
                />
                <span
                  v-if="v$.collaborator.cpf.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="v$.collaborator.cpf.minLength.$invalid"
                  class="invalid-feedback"
                >
                  Formato: 000.000.000-00
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  E-mail: <span class="text-danger">*</span>
                </label>
                <input
                  type="email"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.collaborator.email.$errors.length,
                  }"
                  placeholder="nome@organizacao.com.br"
                  v-model="v$.collaborator.email.$model"
                />
                <span
                  v-if="v$.collaborator.email.email.$invalid"
                  class="invalid-feedback"
                >
                  E-mail inválido
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">Telefone:</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.collaborator.phone.$errors.length,
                  }"
                  placeholder="(00) 98888-8888"
                  v-model="v$.collaborator.phone.$model"
                  v-maska="['(##) ####-####', '(##) #####-####']"
                />
                <span
                  v-if="v$.collaborator.phone.minLength.$invalid"
                  class="invalid-feedback"
                >
                  Formato de telefone inválido <br />
                  Ex: (00) 98888-8888 / (00) 8888-8888
                </span>
              </div>
              <!--<div class="col-sm-6">
                <label class="form-label">
                  Contratação: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid':
                      v$.collaborator.contratationType.$errors.length,
                  }"
                  v-model="v$.collaborator.contratationType.$model"
                >
                  <option value="">Selecione um tipo de contratação</option>
                  <option
                    v-for="contratationType in contratationTypes"
                    :key="contratationType.enum"
                    :value="contratationType.enum"
                    :title="contratationType.name"
                  >
                    {{ contratationType.name }}
                  </option>
                </select>
                <span
                  v-if="v$.collaborator.contratationType.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>-->
              <!--<div class="col-sm-6">
                <label class="form-label">
                  Estado Civil: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.collaborator.civilState.$errors.length,
                  }"
                  v-model="v$.collaborator.civilState.$model"
                >
                  <option value="">Selecione um estado civil</option>
                  <option
                    v-for="civilState in civilStates"
                    :key="civilState.enum"
                    :value="civilState.enum"
                    :title="civilState.name"
                  >
                    {{ civilState.name }}
                  </option>
                </select>
                <span
                  v-if="v$.collaborator.civilState.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>-->
              <!--<div class="col-sm-6">
                <label class="form-label">
                  Sexo: <span class="text-danger">*</span>
                </label>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input form-check-input-secondary"
                      id="genderF"
                      value="FEMININO"
                      v-model="collaborator.gender"
                    />
                    <label class="form-check-label" for="genderF">
                      Feminino
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input form-check-input-secondary"
                      id="genderM"
                      value="MASCULINO"
                      v-model="collaborator.gender"
                    />
                    <label class="form-check-label" for="genderM">
                      Masculino
                    </label>
                  </div>
                </div>
                <span
                  v-if="v$.collaborator.gender.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>-->
              <div class="col-sm-6">
                <label class="form-label">
                  Data de Admissão: <span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.collaborator.admissionDate.$errors.length,
                  }"
                  placeholder="00/00/0000"
                  v-model="v$.collaborator.admissionDate.$model"
                />
                <span
                  v-if="v$.collaborator.admissionDate.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="v$.collaborator.admissionDate.minLength.$invalid"
                  class="invalid-feedback"
                >
                  Formato: 00/00/0000
                </span>
                <span
                  v-if="v$.collaborator.admissionDate.maxLength.$invalid"
                  class="invalid-feedback"
                >
                  Formato: 00/00/0000
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Possui Matricula: <span class="text-danger">*</span>
                </label>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input form-check-input-secondary"
                      id="hasRegistrationNo"
                      value="false"
                      v-model="collaborator.hasRegistration"
                    />
                    <label class="form-check-label" for="hasRegistrationNo">
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input form-check-input-secondary"
                      id="hasRegistrationYes"
                      value="true"
                      v-model="collaborator.hasRegistration"
                    />
                    <label class="form-check-label" for="hasRegistrationYes">
                      Sim
                    </label>
                  </div>
                </div>
                <span
                  v-if="v$.collaborator.hasRegistration.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Matrícula:
                  <span
                    v-if="collaborator.hasRegistration == 'true'"
                    class="text-danger"
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.collaborator.registration.$errors.required,
                  }"
                  placeholder="Matrícula"
                  v-model="v$.collaborator.registration.$model"
                />
                <span
                  v-if="
                    v$.collaborator.registration.requiredIfRegistration.$invalid
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <!--<div class="col-sm-12">
                <label class="form-label">
                  Categoria do E-Social: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid':
                      v$.collaborator.eSocialCategory.$errors.length,
                  }"
                  v-model="v$.collaborator.eSocialCategory.$model"
                >
                  <option value="">Selecione uma categoria</option>
                  <optgroup
                    v-for="(category, index) in eSocialCategories"
                    :key="index"
                    :label="category.name"
                  >
                    <option
                      v-for="item in category.items"
                      :key="item.code"
                      :value="item.code"
                      :title="item.name"
                    >
                      {{ item.code }} - {{ item.name }}
                    </option>
                  </optgroup>
                </select>
                <span
                  v-if="v$.collaborator.eSocialCategory.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>-->
              <div class="text-end">
                <router-link
                  to="/managers/collaborators"
                  class="btn btn-link text-secondary"
                  title="Cancelar"
                >
                  Cancelar
                </router-link>
                <button
                  type="submit"
                  class="btn btn-secondary ms-2"
                  title="Atualizar"
                  :disabled="v$.$invalid || disableButton"
                >
                  <span v-if="disableButton">
                    <i class="ph-spinner spinner" />
                  </span>
                  <span v-else>Atualizar</span>
                </button>
              </div>
            </template>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  minLength,
  maxLength,
  email,
  requiredIf,
} from '@vuelidate/validators'
import { maska } from 'maska'
import { mapGetters } from 'vuex'
import { dateBrToIso } from '@/utils/date'
import PATHS from '@/router/routesMap'

export default {
  name: 'EditCollaborator',

  components: {
    LoadingData,
  },

  setup() {
    return { v$: useVuelidate() }
  },

  directives: { maska },

  data() {
    return {
      cpf: this.$route.params.cpf,
      loadData: true,
      unities: [],
      departments: [],
      positions: [],
      // contratationTypes: [],
      // civilStates: [],
      // eSocialCategories: [],
      collaborator: {
        unity: '',
        department: '',
        position: '',
        name: '',
        birthday: '',
        cpf: '',
        email: '',
        phone: '',
        // contratationType: '',
        // civilState: 'OUTROS',
        // gender: '',
        admissionDate: '',
        hasRegistration: '',
        registration: '',
        // eSocialCategory: '',
      },
      disableButton: false,
    }
  },

  computed: {
    ...mapGetters(['companyCode']),
  },

  watch: {
    'collaborator.unity': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== '') {
          this.getDepartments()
        }
      },
      immediate: true,
    },
    'collaborator.department': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== '') {
          this.getPositions()
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getCollaborators() {
      try {
        this.loadData = true

        const response = await this.$store.dispatch('getCollaborators', {
          companyCode: this.companyCode,
          search: this.cpf,
        })

        if (response && response.length > 0) {
          const [data] = response

          this.collaborator = {
            unity: data.CODIGOUNIDADE,
            department: data.CODIGOSETOR,
            position: data.CODIGOCARGO,
            name: data.NOME,
            birthday: dateBrToIso(data.DATA_NASCIMENTO),
            cpf: data.CPFFUNCIONARIO,
            email: data.EMAILCORPORATIVO,
            phone: data.TELEFONECELULAR,
            // contratationType: '',
            // civilState: 'OUTROS',
            // gender: '',
            admissionDate: dateBrToIso(data.DATA_ADMISSAO),
            hasRegistration:
              data.MATRICULAFUNCIONARIO !== '' ? 'true' : 'false',
            registration: data.MATRICULAFUNCIONARIO,
            // eSocialCategory: '',
          }
        }
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getParams() {
      try {
        this.unities = await this.$store.dispatch('getUnities', {
          companyCode: this.companyCode,
        })
        /* this.contratationTypes = await this.$store.dispatch('getContractTypes')
        this.civilStates = await this.$store.dispatch('getCivilStates')
        this.eSocialCategories = await this.$store.dispatch(
          'getEsocialCategories',
        ) */
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getDepartments() {
      try {
        this.departments = await this.$store.dispatch('getDepartments', {
          companyCode: this.companyCode,
          unityCode: this.collaborator.unity,
        })

        if (this.departments.length === 0) {
          this.$root.$refs.notification.warning(
            'Não há setores disponíveis para esta unidade. Por favor, escolha outra opção.',
          )
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getPositions() {
      try {
        this.positions = await this.$store.dispatch('getPositions', {
          companyCode: this.companyCode,
          unityCode: this.collaborator.unity,
          departmentCode: this.collaborator.department,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async save() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'updateCollaborator',
            this.collaborator,
          )
          this.$root.$refs.notification.success(response.message)
          this.disableButton = false
          this.$router.push(PATHS.MANAGER_COLLABORATORS)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      collaborator: {
        unity: {
          required,
        },
        department: {
          required,
        },
        position: {
          required,
        },
        name: {
          required,
        },
        birthday: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        cpf: {
          required,
          minLength: minLength(14),
          maxLength: maxLength(14),
        },
        email: {
          requiredIfFoo: requiredIf(!this.collaborator.phone),
          email,
        },
        phone: {
          requiredIfEmail: requiredIf(!this.collaborator.email),
          minLength: minLength(14),
          maxLength: maxLength(15),
        },
        /* contratationType: {
          required,
        },
        civilState: {
          required,
        },
        gender: {
          required,
        }, */
        admissionDate: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        hasRegistration: {
          required,
        },
        registration: {
          requiredIfRegistration: requiredIf(
            this.collaborator.hasRegistration === 'true',
          ),
        },
        /* eSocialCategory: {
          required,
        }, */
      },
    }
  },

  mounted() {
    this.getParams()
    this.getCollaborators()
  },
}
</script>
