<template>
  <div class="table-responsive">
    <table
      class="table table-sm table-bordered table-striped"
      :id="dataTable.id"
    >
      <thead>
        <tr>
          <th>Nome</th>
          <th>CPF</th>
          <th>Matrícula</th>
          <th>Unidade</th>
          <th>Setor</th>
          <th>Cargo</th>
          <th class="text-center">Situação</th>
          <th class="text-center">Detalhes</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="collaborator in collaborators"
          :key="collaborator.MATRICULAFUNCIONARIO"
        >
          <td>{{ collaborator.NOME }}</td>
          <td>{{ collaborator.CPFFUNCIONARIO }}</td>
          <td>{{ collaborator.MATRICULAFUNCIONARIO }}</td>
          <td>{{ collaborator.NOMEUNIDADE }}</td>
          <td>{{ collaborator.NOMESETOR }}</td>
          <td>{{ collaborator.NOMECARGO }}</td>
          <td
            v-html="statusCollaboratorLabel(collaborator.SITUACAO)"
            class="text-center"
          />
          <td class="text-center">
            <router-link
              :to="`collaborators/${collaborator.CPFFUNCIONARIO}`"
              class="btn btn-success btn-icon btn-sm"
              title="Visualizar Detalhes"
            >
              <i class="ph-info ph-sm"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import '@/utils/basicDataTableExtend'
import { statusCollaboratorLabel } from '@/utils/strings'

export default {
  name: 'CollaboratorsTable',

  props: {
    collaborators: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListCollaborators',
        columnDefs: [
          {
            targets: [7],
            orderable: false,
            width: 100,
          },
        ],
      },
      statusCollaboratorLabel,
    }
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>

<style scoped>
td {
  padding: 0.5rem !important;
}
</style>
